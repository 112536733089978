<template>
	<FullPageLoader />

</template>
<script>
import axios from 'axios'
import Config from '../../config'
import { crypt } from '../../utils'
import FullPageLoader from '../Utils/FullPageLoader.vue'

export default {
	components: {
		FullPageLoader
	},
    data() {
        return {
            token: null
        }
    },
    methods: {
        async processUser() {
            const data = {
                access_token: this.$route.query.access_token,
                ean: this.$route.query.ean
            }
            const res = await axios.post(`${Config.backend_url}/sso-edulib/`, data)
            this.token = res.data.token
			if (!res.data.token.student) {
				this.$router.push({ name: 'Platform', query: { token: res.data.token } })
			}
			else{
				this.$store.state.student = {
					username: res.data.token.student.username,
					class_id: res.data.token.student.class_id,
					id: res.data.token.student.id,
					token: res.data.token.access
				}
				localStorage.setItem('studentToken', this.$store.state.student.token)
				this.$store.state.toast = {
					status: 'success',
					text: "Connexion réussie",
				}
				this.$router.push({name: 'StudentPlaceHome', params: {classId: crypt(this.$store.state.student.class_id.toString())}})
			}
        },

    },
	created() {
		this.processUser()
	}

} 
</script>
